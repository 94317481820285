import icons from "../icons/icons"
const $ = require("jquery") // if we need
import Swiper, { Navigation, Pagination, EffectCoverflow } from "swiper"
import "swiper/swiper-bundle.css"
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js"
import "@fancyapps/ui/dist/fancybox.css"
import ModalVideo from "modal-video"
import "modal-video/css/modal-video.css"
Fancybox.bind("[data-fancybox]", {
  // Your options go here
})

Swiper.use([Navigation, Pagination, EffectCoverflow])

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },
  load() {
    console.log("load")
    setTimeout(() => {
      $(".preloader-anim").addClass("loaded")
    }, 250)
    setTimeout(() => {
      $(".preloader").addClass("loaded")
    }, 1100)
  },
  heroAfterLoadedFn() {
    const hero = document.querySelector(".hero")
    if (hero) {
      setTimeout(() => {
        hero.classList.add("loaded")
      }, 1200)
    }
  },
  modalVideoFn() {
    new ModalVideo(".js-modal-btn", {
      autoplay: 1,
    })
  },
  inputFn() {
    const inputItem = document.querySelectorAll(
      ".input-item input, .input-item textarea"
    )
    inputItem.forEach((e) => {
      e.addEventListener("focusin", () => {
        e.parentNode.classList.add("input-item--focused")
      })
      e.addEventListener("focusout", () => {
        if (e.value == "") {
          e.parentNode.classList.remove("input-item--focused")
        } else {
          e.parentNode.classList.add("input-item--focused")
        }
      })
    })
  },

  menuButtonFn() {
    const menuBtn = document.querySelector(".js-menu-button")
    const menu = document.querySelector(".js-menu")
    const header = document.querySelector(".header")

    menuBtn.addEventListener("click", function () {
      this.classList.toggle("opened")
      menu.classList.toggle("opened")
      header.classList.toggle("menu-opened")
      document.querySelector("html").classList.toggle("menu-opened")
      document.querySelector("body").classList.toggle("menu-opened")
    })
  },
  mobileMenuDropdownFn() {
    const mobileMenuBtn = document.querySelectorAll(".js-mobile-dropdown")

    for (let index = 0; index < mobileMenuBtn.length; index++) {
      const e = mobileMenuBtn[index]

      e.addEventListener("click", function () {
        let mobileMenuBtnOpened = document.querySelectorAll(
          ".js-mobile-dropdown.opened"
        )
        e.classList.toggle("opened")
        for (let i = 0; i < mobileMenuBtnOpened.length; i++) {
          const eOpened = mobileMenuBtnOpened[i]
          eOpened.classList.remove("opened")
        }
      })
    }
  },

  headerFn() {
    const detailPage = document.querySelector(".detail-page")
    const header = document.querySelector("header")
    if (detailPage) {
      header.classList.add("header--light")
    } else {
      header.classList.remove("header--light")
    }
    if (header) {
      if (window.scrollY >= 100) {
        header.classList.add("header--sticky")
        if (detailPage) {
          header.classList.remove("header--light")
        }
      } else {
        header.classList.remove("header--sticky")
        if (detailPage) {
          header.classList.add("header--light")
        }
      }
    }
  },
  headerDisabledFn() {
    const header = document.querySelector("header")

    if (header) {
      window.document.body.addEventListener("wheel", function (event) {})

      $(window).scroll(function () {
        if (header.classList.contains("header--sticky")) {
          var currentscroll_position = $(window).scrollTop()
          $(window).on("scroll", function () {
            if ($(window).scrollTop() > 100) {
              Get_page_scroll_direction()
            }
          })
          function Get_page_scroll_direction() {
            var running_scroll_position = $(window).scrollTop()
            if (running_scroll_position > currentscroll_position) {
              header.classList.add("header--disabled")
            } else {
              header.classList.remove("header--disabled")
            }
            currentscroll_position = running_scroll_position
          }
        } else {
          header.classList.remove("header--disabled")
        }
      })
    }
  },

  resized() {
    console.log("resized")
  },

  headerLanguageFn() {
    const headerLanguageBtn = document.querySelector(".js-header-language-btn")
    if (headerLanguageBtn) {
      window.addEventListener("click", function (e) {
        if (document.querySelector(".header__language").contains(e.target)) {
          headerLanguageBtn.parentNode.classList.toggle("opened")
        } else {
          headerLanguageBtn.parentNode.classList.remove("opened")
        }
      })
      window.addEventListener("click", function (e) {
        if (
          document.querySelector(".header__language-wrapper").contains(e.target)
        ) {
          document
            .querySelector(".header__language-wrapper")
            .parentNode.classList.add("opened")
        }
      })
    }
  },
  searchButtonFn() {
    const searchBtn = document.querySelector("#js-search-button")
    const Input = document.querySelector(".header__search-input input")
    if (searchBtn) {
      searchBtn.addEventListener("click", function () {
        this.parentNode.classList.toggle("opened")
        if (this.parentNode.classList.contains("opened")) {
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          }
        } else {
          document
            .querySelector(".header__search-results")
            .classList.remove("opened")
        }
        Input.addEventListener("keyup", () => {
          if (Input.value.length > 3) {
            document
              .querySelector(".header__search-results")
              .classList.add("opened")
          } else {
            document
              .querySelector(".header__search-results")
              .classList.remove("opened")
          }
        })
      })
    }
  },

  heroAnnouncementSlider() {
    const swiper = new Swiper(".js-hero-announcement-slider", {
      loop: true,
      pagination: {
        el: ".hero__slider .swiper-pagination",
        type: "bullets",
      },
    })
  },
  cvUploadFn() {
    $(".js-cv-upload").click(function () {
      $(".cv-upload").trigger("click")
    })
    $(".cv-upload").on("change", function () {
      if ($(".cv-upload").val() !== "") {
        $(".js-cv-upload")
          .find(".input-item__placeholder")
          .text("Dosya Seçildi")
      }
    })
  },
  indexServicesSlider() {
    const swiper = new Swiper(".js-index-services-slider", {
      loop: true,
      slidesPerView: 3.2,
      spaceBetween: 40,
      speed: 700,
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 20,
        },
        577: {
          slidesPerView: 1.5,
          spaceBetween: 25,
        },
        769: {
          slidesPerView: 2.1,
          spaceBetween: 20,
        },
        993: {
          slidesPerView: 2.5,
          spaceBetween: 30,
        },
        1201: {
          slidesPerView: 2.8,
          spaceBetween: 30,
        },
        1441: {
          slidesPerView: 3.2,
          spaceBetween: 40,
        },
      },
    })
  },

  counter() {
    $(window).scroll(function () {
      if ($(".numbers").length > 0) {
        var numbersTop = $(".numbers").offset().top - 400
        if ($(window).scrollTop() > numbersTop) {
          $(".js-counter-number").each(function () {
            var $this = $(this),
              countTo = $this.attr("data-count")
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },

              {
                duration: 2000,
                easing: "swing",
                step: function () {
                  $this.text(Math.floor(this.countNum))
                },
                complete: function () {
                  $this.text(this.countNum)
                },
              }
            )
          })
          return true
        } else {
          return false
        }
      }
    })
  },

  init: function () {
    app.iconSpriteFn()
    app.load()
    app.headerFn()
    app.headerDisabledFn()
    app.menuButtonFn()
    app.heroAfterLoadedFn()
    app.headerLanguageFn()
    app.searchButtonFn()
    app.heroAnnouncementSlider()
    app.indexServicesSlider()
    app.counter()
    app.inputFn()
    app.cvUploadFn()
    app.modalVideoFn()
    app.mobileMenuDropdownFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
function docScrolled(fn) {
  window.addEventListener("scroll", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})
docScrolled(() => {
  app.headerFn()
})

export default { docResized, docScrolled, docReadied }
